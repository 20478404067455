"use client";
import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "./button";
import { cn } from "@lib/utils";
import { useCookies } from "next-client-cookies";
import { classNames } from "@lib/helpers";
import { useIntl } from "react-intl";

interface SearchProps {
  placeholder?: string;
  btnText?: string;
  handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  handleCancel: () => void;
  value: string;
  className?: string;
}
const Search = ({
  placeholder,
  btnText,
  value,
  onChange,
  handleCancel,
  handleSubmit,
  className,
}: SearchProps) => {
  const intl = useIntl();
  const commonTranslation: any = intl.messages?.common;
  const cookies = useCookies();
  const isArabic = cookies.get("lang") === "ar";

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center relative z-10">
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={cn(
              "transition w-full h-12 text-sm rounded-[8px] font-medium focus:ring-primary text-gray-700 focus:outline-none border border-[#C0C1C1] focus:border-neutral-400 placeholder:text-neutral-400 focus:ring-0 ring-0",
              isArabic && value ? "pl-12 pr-4" : "",
              !isArabic && value ? "pr-12 pl-4" : "",
              className
            )}
          />

          <div
            className={classNames(
              "absolute flex items-center gap-2",
              isArabic ? "left-1 top-1 bottom-1" : "right-1 top-1 bottom-1"
            )}
          >
            {value ? (
              <button
                type="button"
                onClick={handleCancel}
                className="inline-block p-1"
              >
                <XMarkIcon className="w-6" />
              </button>
            ) : null}

            <Button className="!bg-[#F2F2F2] text-[14px] p-0 h-10 w-10 text-neutral-700 font-medium rounded-[4px]  sm:inline-flex hidden">
              <IoSearchOutline className="w-5 h-5" />
            </Button>
          </div>
        </div>

        <Button className="px-5 w-full hidden mt-3">
          {btnText || commonTranslation?.["search-jobs"]}
        </Button>
      </form>
    </div>
  );
};

export default Search;
